$(function () {

// nav
$('.burgermenu').on('click', function() {
    $('.nav-fly').toggleClass('opened');
	$(this).toggleClass('active')
})

var delay = 0.3;
$('.mainmenu li').each(function() {
	$(this).css('animation-delay', delay + 's');
	delay += 0.3;
});
// Titles animation
// const titles = document.querySelectorAll('.markoff_title');
// const windowHeight = window.innerHeight;
// const titlesAnimated = []; // массив заголовков, которые уже были отработаны

// function animateTitle(title) {
//   const words = title.textContent.split(' ');
//   title.textContent = '';
//   words.forEach(word => {
//     const wordSpan = document.createElement('strong');
//     wordSpan.classList.add('parent');
//     const letters = word.split('');
//     letters.forEach((letter, i) => {
//       const span = document.createElement('span');
//       span.textContent = letter;
//       span.style.animationDelay = `${i * 50}ms`;
//       wordSpan.append(span);
//     });
//     title.append(wordSpan);
//   });

//   // добавление класса fade-in только к видимым элементам
//   const spans = title.querySelectorAll('span');
//   spans.forEach(span => {
//     const rect = span.getBoundingClientRect();
//     if (rect.top <= windowHeight) {
//       span.classList.add('fade-in');
//     } else {
//       window.addEventListener('scroll', () => {
//         const rect = span.getBoundingClientRect();
//         if (rect.top <= windowHeight) {
//           span.classList.add('fade-in');
//           window.removeEventListener('scroll', this);
//         }
//       });
//     }
//   });

//   titlesAnimated.push(title); // добавляем заголовок в массив отработанных
// }

// titles.forEach(title => {
//   const rect = title.getBoundingClientRect();
//   if (rect.top <= windowHeight && !titlesAnimated.includes(title)) {
//     animateTitle(title);
//   } else {
//     window.addEventListener('scroll', () => {
//       const rect = title.getBoundingClientRect();
//       if (rect.top <= windowHeight && !titlesAnimated.includes(title)) {
//         animateTitle(title);
//         window.removeEventListener('scroll', this);
//       }
//     });
//   }
// });

const titles = document.querySelectorAll('.markoff_title');
const windowHeight = window.innerHeight;
const titlesAnimated = []; // массив заголовков, которые уже были отработаны

function animateTitle(title) {
  const words = title.textContent.split(' ');
  title.textContent = '';
  words.forEach(word => {
    const wordSpan = document.createElement('strong');
    wordSpan.classList.add('parent');
    const letters = word.split('');
    letters.forEach((letter, i) => {
      const span = document.createElement('span');
      span.textContent = letter;
      span.style.animationDelay = `${i * 50}ms`;
      wordSpan.append(span);
    });
    title.append(wordSpan);
  });

  // добавление класса fade-in только к видимым элементам
  const spans = title.querySelectorAll('span');
  spans.forEach((span, index) => {
    const rect = span.getBoundingClientRect();
    if (rect.top <= windowHeight) {
      span.classList.add('fade-in');
      if (title.classList.contains('delay')) {
        span.style.animationDelay = `${index * 50 + 1150}ms`; // Добавляем задержку для каждой буквы с классом 'delay'
      }
    } else {
      window.addEventListener('scroll', () => {
        const rect = span.getBoundingClientRect();
        if (rect.top <= windowHeight) {
          span.classList.add('fade-in');
          if (title.classList.contains('delay')) {
            span.style.animationDelay = `${index * 50 + 1150}ms`; // Добавляем задержку для каждой буквы с классом 'delay'
          }
          window.removeEventListener('scroll', this);
        }
      });
    }
  });

  titlesAnimated.push(title); // добавляем заголовок в массив отработанных
}

titles.forEach(title => {
  const rect = title.getBoundingClientRect();
  if (rect.top <= windowHeight && !titlesAnimated.includes(title)) {
    animateTitle(title);
  } else {
    window.addEventListener('scroll', () => {
      const rect = title.getBoundingClientRect();
      if (rect.top <= windowHeight && !titlesAnimated.includes(title)) {
        animateTitle(title);
        window.removeEventListener('scroll', this);
      }
    });
  }
});



	gsap.registerPlugin(ScrollTrigger);
  // init controller
  var controller = new ScrollMagic.Controller();
	
  // build about image parallax

if (document.querySelector('#parallaxContainer')) {
	var aboutImg = gsap.to("#parallax-img", {yPercent: -30});

	var aboutImgscene = new ScrollMagic.Scene({triggerElement: "#parallaxContainer", offset: 100, duration: 1000})
		.setTween(aboutImg)
		.addIndicators()
		.addTo(controller);
}
if (document.querySelector('#parallax-about')) {
	var aboutText = gsap.to("#parallax-about-text", {yPercent: -30});

	var aboutTextScene = new ScrollMagic.Scene({triggerElement: "#parallax-about", offset: 100, duration: document.querySelector("#parallax-about").offsetHeight})
		.setTween(aboutText)
		.addIndicators()
		.addTo(controller);
}
if (document.querySelector('.about-galery')) {
	var aboutGaleryOne = gsap.to("#about-galery__1", {y: -150});

	var aboutGaleryOneScene = new ScrollMagic.Scene({triggerElement: ".about-galery", offset: 0, duration: document.querySelector(".about-galery").offsetHeight})
		.setTween(aboutGaleryOne)
		.addIndicators()
		.addTo(controller);
	
	var aboutGaleryTwoo = gsap.to("#about-galery__2", {y: 100});

	var aboutGaleryTwooScene = new ScrollMagic.Scene({triggerElement: ".about-galery", offset: 0, duration: document.querySelector(".about-galery").offsetHeight})
		.setTween(aboutGaleryTwoo)
		.addIndicators()
		.addTo(controller);
	
	var aboutGaleryTree = gsap.to("#about-galery__3", {y: -100});

	var aboutGaleryTreeScene = new ScrollMagic.Scene({triggerElement: ".about-galery", offset: 0, duration: document.querySelector(".about-galery").offsetHeight})
		.setTween(aboutGaleryTree)
		.addIndicators()
		.addTo(controller);

}
if (document.querySelector('.about-target')) {
	var aboutText = gsap.to("#paralaxtarger", {yPercent: -30});

	var aboutTextScene = new ScrollMagic.Scene({triggerElement: ".about-target", offset: 100, duration: document.querySelector(".about-target").offsetHeight})
		.setTween(aboutText)
		.addIndicators()
		.addTo(controller);
}

if (document.querySelector('.fly-text')) {
	var flyText = gsap.to("#flyText", {xPercent: -330});

	var flyTextScene = new ScrollMagic.Scene({
		triggerElement: ".fly-text",
		offset: 0
	})
		.setTween(flyText)
		.setPin("#flyText", {pushFollowers: true})
		.addIndicators()
		.addTo(controller)
		.duration(window.innerWidth >= 768 ? '400%' : '250%');
}

// if (document.querySelector('.about-header')) {
// 	var flyAboutText = gsap.to("#flyAboutText", {xPercent: -200});

// 	var flyAboutTextScene = new ScrollMagic.Scene({
// 		triggerElement: ".about-block",
// 		offset: document.querySelector('.about-block') ,
// 		// duration: 2000
// 	})
// 		.setTween(flyAboutText)
// 		.setPin("#flyAboutText")
// 		.addIndicators()
// 		.addTo(controller)
// 		.duration(window.innerWidth >= 991 ? 1500 : 600);
// }

gsap.to(".marquee-text", { 
	xPercent: -250,
	opacity: 0,
	duration: .1,
	scrollTrigger: {
		trigger: '.about-header',
		pin: ".marquee-text",
		scrub: 1,
		start: "top top",
		end: '+=3000px',
	}
})

gsap.to(".design-element-text", { 
	x: '-1050px',
	// opacity: 0,
	duration: .1,
	scrollTrigger: {
		trigger: '.project__description',
		// pin: ".design-element-text",
		scrub: 1,
		start: "top center",
		end: '+=3000px',
	}
})

if (document.querySelector('#pinContainer')) {

	var wipeAnimation = new TimelineMax()
		// animate to second panel
		.to("#slideContainer", 0.5, {z: -150})		// move back in 3D space
		.to("#slideContainer", 1,   {x: "-25%"})	// move in to first panel
		.to("#slideContainer", 0.5, {z: 0})				// move back to origin in 3D space
	// animate to third panel
		.to("#slideContainer", 0.5, {z: -150, delay: 1})
		.to("#slideContainer", 1,   {x: "-50%"})
		.to("#slideContainer", 0.5, {z: 0})
  // // animate to forth panel
  // .to("#slideContainer", 0.5, {z: -150, delay: 1})
  // .to("#slideContainer", 1,   {x: "-75%"})
  // .to("#slideContainer", 0.5, {z: 0});

// create scene to pin and link animation
	new ScrollMagic.Scene({
		triggerElement: "#pinContainer",
		triggerHook: "onLeave",
		duration: "500%"
	})
		.setPin("#pinContainer")
		.setTween(wipeAnimation)
		.addIndicators() // add indicators (requires plugin)
		.addTo(controller);
}

// Получите все элементы с классом ".your-element"
const elements = document.querySelectorAll('.slideUp');

// Обойдите каждый элемент и создайте сцену для него
elements.forEach((element) => {
  const scene = new ScrollMagic.Scene({
    triggerElement: element,
    triggerHook: 0.8, // Начать сцену, когда элемент находится в пределах 80% видимости окна
  })
    .setClassToggle(element, 'visible') // Добавить класс "visible" при активации сцены для текущего элемента
    .addTo(controller);
});




// Обработчик клика по ссылке для прокрутки к объекту
const scrollLinks = document.querySelectorAll('.scroll-link');
scrollLinks.forEach((link) => {
  link.addEventListener('click', (e) => {
    e.preventDefault();
    const target = link.getAttribute('href');
    const offset = document.querySelector(target).offsetTop;

    window.scrollTo({
      top: offset,
      behavior: 'smooth',
    });
  });
});




if(document.querySelector('#myCanvas')) {
	// Получаем DOM-элемент канваса
	const canvas = document.querySelector('#myCanvas');

	// Создаем сцену
const scene = new THREE.Scene();

// Создаем камеру
const camera = new THREE.PerspectiveCamera(25, window.innerWidth / window.innerHeight, 2, 1000);
camera.position.set(0, 15, 0);

// Создаем рендерер
const renderer = new THREE.WebGLRenderer({ canvas, alpha: true });
renderer.setSize(window.innerWidth, window.innerHeight);

// Добавляем сетку для отображения осей координат
const axesHelper = new THREE.AxesHelper(5);
scene.add(axesHelper);

// Создаем загрузчик GLTF-файлов
const loader = new THREE.GLTFLoader();

// Загружаем модель
loader.load('../model/model.glb', (gltf) => {
  // Удаляем все дочерние объекты сцены
  scene.remove(...scene.children);

  // Добавляем модель на сцену
  const model = gltf.scene.children[0];
  scene.add(model);

  // Создаем материал для модели с прозрачностью и контурами
  const material = new THREE.MeshBasicMaterial({
    transparent: true,
    opacity: 0.5,
    side: THREE.DoubleSide,
    color: 0x5A5A5A,
    wireframe: true
  });

  // Заменяем материалы всех Mesh-объектов на сцене на наш материал
  scene.traverse((obj) => {
    if (obj.isMesh) {
      obj.material = material;
    }
  });

  // Создаем экземпляр класса OrbitControls
  const controls = new THREE.OrbitControls(camera, renderer.domElement);
  controls.enableZoom = false;
  controls.enablePan = false;

//   model.rotation.y = -Math.PI / 4;

  // Функция рендеринга сцены
  function render() {
    requestAnimationFrame(render);

    // Получаем значение скролла страницы и используем его для установки углов вращения модели
    const scrollY = window.scrollY;
    model.rotation.x = -scrollY * 0.0010;
    model.rotation.y = scrollY * 0.0010;

    renderer.render(scene, camera);
  }
  render();
  // Привязка обработчика событий к странице
  window.addEventListener('scroll', () => {
    // Перерисовываем сцену при изменении скролла страницы
    renderer.render(scene, camera);
  });
});

}




// КАКРУСЕЛЬ

const swiper = new Swiper('.team-slider', {
	// Optional parameters
	// loop: true,
	slidesPerView: 3.5,
	spaceBetween: 100,
	// If we need pagination
	pagination: {
	  el: '.swiper-pagination',
	},
  
	// Navigation arrows
	navigation: {
	  nextEl: '.swiper-button-next',
	  prevEl: '.swiper-button-prev',
	},
	breakpoints: {
		// when window width is >= 320px
		280: {
			slidesPerView: 1.5,
			spaceBetween: 20
		},
		// when window width is >= 480px
		480: {
			slidesPerView: 2.5,
			spaceBetween: 30
		},
		// when window width is >= 640px
		768: {
			slidesPerView: 2.5,
			spaceBetween: 60
		},
		1280: {
			slidesPerView: 2.5,
			spaceBetween: 100
		},
		1480: {
			slidesPerView: 3.5,
			spaceBetween: 100
		}
	  }
});

});


  